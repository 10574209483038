(function () {
    angular.module('aerosApp')
        .directive('aflLinkmapEndEventSection', aflLinkmapEndEventSection);

    function aflLinkmapEndEventSection() {
        return {
            restrict: 'E',
            scope: {
                event: '=',
                imageMap: '=',
                iconMap: '=',
                unit: '='
            },
            controller: aflLinkmapEndEventSectionController,
            templateUrl: '/static/javascript/directives/afl-linkmap/afl-linkmap-end-event-section.html'
        }
    }

    aflLinkmapEndEventSectionController.$inject = ['$scope', 'LinkmapService'];

    function aflLinkmapEndEventSectionController($scope, LinkmapService) {
        angular.extend($scope, {
            getLocationPrecision: LinkmapService.getLocationPrecision
        });
    }
})();
